import React from 'react'

export default function LoadingSell() {
  return (
    <>
  
            <div className="pokeball mt-10" id="pokeball-normal"></div>
   
    </>
  )
}
